import 'select2';
import 'select2/dist/css/select2.css';

// Initialize Select2
export function initializeSelect2(selector) {
    $(selector).select2();
}


export function initializeFilters() {
    // Initialize Select2
    const category = document.getElementById('filter-category');
    const destination = document.getElementById('filter-destination');
    const total_days = document.getElementById('filter-days');

    if (!(category) && !(destination) && !(total_days)) {
        return; // Exit the function early to avoid rendering tours
    }

    // Initialize Select2 after fetching the select elements
    if (category && !$(category).hasClass('select2-hidden-accessible')) {
        $(category).select2({ theme: 'bootstrap-5' });
    }
    if (destination && !$(destination).hasClass('select2-hidden-accessible')) {
        $(destination).select2({ theme: 'bootstrap-5' });
    }
    if (total_days && !$(total_days).hasClass('select2-hidden-accessible')) {
        $(total_days).select2({ theme: 'bootstrap-5' });
    }
}

export function fetchTours() {
    const category = document.getElementById('filter-category');
    const destination = document.getElementById('filter-destination');
    const total_days = document.getElementById('filter-days');

    if (!(category) && !(destination) && !(total_days)) {
        return; // Exit the function early to avoid rendering tours
    }

    // Initialize Select2 after fetching the select elements
    if (category && !$(category).hasClass('select2-hidden-accessible')) {
        $(category).select2({ theme: 'bootstrap-5' });
    }
    if (destination && !$(destination).hasClass('select2-hidden-accessible')) {
        $(destination).select2({ theme: 'bootstrap-5' });
    }
    if (total_days && !$(total_days).hasClass('select2-hidden-accessible')) {
        $(total_days).select2({ theme: 'bootstrap-5' });
    }

    // Get values from Select2 dropdowns
    const categoryValues = $(category).select2('val');
    const destinationValues = $(destination).select2('val');
    const totalDaysValues = $(total_days).select2('val');

    const categoryTexts = $("#filter-category").select2('data').map(function(elem){
        return elem.text;  // extract text from each selected option
    });
    
    let url = `/api/method/zoni_edu.controller.get_all_published_tours`;  // We are fetching all tours for filtering on frontend
  
    // Fetch tours using the Fetch API
    fetch(url, {
        method: 'GET'
    })
        .then(response => {
            if (!response.ok) {
                console.log('Full Response:', response);
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            let tours = data.message;

            // Filter tours based on selected values
            if (categoryValues && categoryValues.length) {
                tours = tours.filter(tour => categoryValues.some(category => tour[category]));
            }

            if (destinationValues && destinationValues.length) {
                tours = tours.filter(tour => destinationValues.includes(tour.destination));
            }

            if (totalDaysValues && totalDaysValues.length) {
                const totalDaysInts = totalDaysValues.map(val => parseInt(val, 10));
                tours = tours.filter(tour => totalDaysInts.includes(tour.total_days));
            }


            // Render tours
            let tourHtml = '';
            let count = 0;  // to keep track of every 3 items

            for (const tour of tours) {
                // For small devices, every tour will have its own carousel-item
                if (window.innerWidth <= 768) {
                    tourHtml += `<div class="carousel-item ${count === 0 ? 'active' : ''}"><div class="row d-flex">`;
                }
                // For medium and large devices, every 3 tours will be grouped into one carousel-item
                else if (count % 3 === 0) {
                    tourHtml += count !== 0 ? `</div></div>` : '';
                    tourHtml += `<div class="carousel-item ${count === 0 ? 'active' : ''}"><div class="row d-flex">`;
                }
                let imageSrc = '';
                if (tour.tour_images && tour.tour_images.length > 0) {
                    const images = tour.tour_images.sort((a, b) => a.sequence - b.sequence);
                    imageSrc = images[0].image;
                }
                tourHtml += `
                <div class="col-12 col-md-4 mb-3">
                    <div class="card flex-fill h-100 position-relative">
                        <img class="img-fluid" alt="${tour.name} Image" src="${imageSrc}">

                        <!-- Overlay over the image -->
                        <div class="overlay_over_image position-absolute top-0 start-0 w-100 h-100" style="background-color: rgba(0, 74, 147, 0.4);"></div>
          
                        
                        <!-- Positioning text over the image -->
                        <div class="position-absolute top-0 start-0 p-2 mt-5 text-primary rounded-end" style="background-color: rgba(255, 215, 0, 1);">
                            <strong>${tour.destination}</strong>
                        </div>
                        
                        <div class="position-absolute top-50 start-50 translate-middle text-center text-white">
                            <h4 style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);">${tour.name}</h4>
                        </div>
                        
                        <div class="position-absolute bottom-0 end-0 p-5">
                            <a href="${tour.detail_link}" class="btn btn-secondary">View Itinerary</a>
                        </div>
                        
                    </div>
                </div>
            `;
                count++;
                if (window.innerWidth <= 768) {
                    tourHtml += '</div></div>'; // Close the carousel-item for small devices after every tour
                }
                else if (count % 3 === 0) {
                    tourHtml += '</div></div>'; // Close the carousel-item for medium and large devices after every 3 tours
                }
            }

            document.querySelector('#carouselExampleIndicators2 .carousel-inner').innerHTML = tourHtml;


            // Calculate the number of carousel items created
            const numberOfSlides = (window.innerWidth <= 768) ? tours.length : Math.ceil(tours.length / 3);

            // Create carousel indicators
            let indicatorsHtml = '';
            for (let i = 0; i < numberOfSlides; i++) {
                if (i === 0) {
                    indicatorsHtml += `<li data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="${i}" class="active"></li>`;
                } else {
                    indicatorsHtml += `<li data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="${i}"></li>`;
                }
            }

            // Update the DOM with the newly created indicators
            document.querySelector('.carousel-indicators').innerHTML = indicatorsHtml;


        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error.message);
        });
}
