export function getLoginStatus() {
    return fetch('/api/method/zoni_edu.zoni_edu.utils.session.check_login_status')
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to fetch login status');
            }
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error('Error fetching login status:', error);
            return { logged_in: false, user: null };
        });
}


export function logoutUser() {
    return fetch('/api/method/zoni_edu.zoni_edu.utils.session.logout_user', {
        method: 'POST', // Ensuring to use POST as it modifies server state
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "X-Frappe-CSRF-Token": frappe.csrf_token
        },
        credentials: 'include' // Ensuring cookies are included for auth if needed
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Failed to log out');
        }
        return response.json(); // Parse JSON response
    })
    .then(data => {
        return data;
    })
    .catch(error => {
        console.error('Error logging out:', error);
        return { success: false, message: error.message };
    });
}


export async function loginUser(email, password) {
    const endpointUrl = '/api/method/login';

    try {
        const response = await fetch(endpointUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                usr: email,
                pwd: password
            })
        });
        const data = await response.json();
        if (data.message === 'Logged In') {
            console.log('User logged in successfully.');
            return true;
        } else {
            console.error('Login failed:', data);
            return false;
        }
    } catch (error) {
        console.error('Error logging in user:', error);
        return false;
    }
}
