let isIntlTelInputLoaded = false;  // private variable


export function getIntlTelInputLoadedStatus() {
    return isIntlTelInputLoaded;
}

export function setIntlTelInputLoadedStatus(status) {
    isIntlTelInputLoaded = status;
}

export function loadAndInitializeIntlTelInput() {
    
    var input = document.querySelector("#intl_phone_id");

    if (!input) {
        console.error('Input element #intl_phone_id not found.');
        return;
    }
    // Initialize with default preferredCountries.
    var preferredCountries = ['US'];  

    fetch('/api/method/zoni_edu.controller.get_country_from_ip', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        let countryCode = data.message;
        if(countryCode === 'Local' || countryCode === 'Unknown') {
            countryCode = 'US';
        }
        
        if (countryCode && countryCode !== 'US') {
            // Add the country code to preferred countries if it's not already included and it's not 'US'
            preferredCountries.push(countryCode.toLowerCase());
        }

        // Now that we've potentially updated preferredCountries, initialize intlTelInput with it.
        window.iti = window.intlTelInput(input, {
            initialCountry: 'US', // You might want to dynamically set this as well based on the IP
            utilsScript: "/assets/zoni_edu/node_modules/intl-tel-input/build/js/utils.js",
            formatOnDisplay: true,
            separateDialCode: true,
            preferredCountries: preferredCountries,
        });

        // Set the country after initialization if needed.
        if(countryCode) {
            try {
                window.iti.setCountry(countryCode.toLowerCase()); // try to set the country
            } catch (error) {
                console.error('Error setting country in intlTelInput:', error); // log the error if it fails
            }
        }

    })
    .catch(error => {
        console.error('Error fetching country based on IP:', error);
    });
}
