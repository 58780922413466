const SUPPORTED_LANGUAGES = [
    'af',
    'am',
    'ar',
    'bg',
    'bn',
    'bs',
    'ca',
    'cs',
    'da',
    'de',
    'el',
    'en',
    'es',
    'et',
    'fa',
    'fi',
    'fil',
    'fr',
    'gu',
    'he',
    'hi',
    'hr',
    'hu',
    'id',
    'is',
    'it',
    'ja',
    'km',
    'kn',
    'ko',
    'ku',
    'lo',
    'lt',
    'lv',
    'mk',
    'ml',
    'mn',
    'mr',
    'ms',
    'my',
    'nl',
    'no',
    'pl',
    'ps',
    'pt',
    'ro',
    'ru',
    'rw',
    'si',
    'sk',
    'sl',
    'sq',
    'sr',
    'sv',
    'sw',
    'ta',
    'te',
    'th',
    'tr',
    'uk',
    'ur',
    'uz',
    'vi',
    'zh'
];

export function translateContext(targetLanguage) {


    // Check if the target language is supported
    if (!SUPPORTED_LANGUAGES.includes(targetLanguage)) {
        console.error('The specified language is not supported.');
        return; // Return without modifying URL if the target language is not supported
    }

    // Retrieve the current URL from the document
    const urlObj = new URL(window.location.href);
    const pathSegments = urlObj.pathname.split('/').filter(segment => segment);

    // Find the segment which is a language code
    const languageSegmentIndex = pathSegments.findIndex(segment => SUPPORTED_LANGUAGES.includes(segment));

    if (languageSegmentIndex > -1) {
        // Replace the found language segment
        pathSegments[languageSegmentIndex] = targetLanguage;
    } else {
        // No language segment found, prepend the target language
        pathSegments.unshift(targetLanguage);
    }

    // Reconstruct the pathname from segments
    urlObj.pathname = '/' + pathSegments.join('/');

    // Redirect to the new URL
    window.location.href = urlObj.toString();
}


export function updateLanguageDropdown() {
   
    // Function to update language display based on the current URL
    function updateLanguageDisplay(currentLanguage) {
        if (SUPPORTED_LANGUAGES.includes(currentLanguage)) {
            const dropdownItems = document.querySelectorAll('.dropdown-item');
            dropdownItems.forEach(item => {
                if (item.getAttribute('data-language') === currentLanguage) {
                    const languageLabel = document.querySelector('.lang-label-text');
                    const languageCode = document.querySelector('.lang-code-text');
                    const languageFlag = document.querySelector('.fi');

                    // Update the UI components with the current language
                    languageLabel.textContent = "Lang";
                    languageCode.textContent = currentLanguage;
                    languageFlag.className = item.querySelector('span').className; // Update flag icon

                    // Update the dropdown button to show the selected language
                    document.querySelector('#languageDropdownMenuButton span').className = item.querySelector('span').className;
                }
            });
        }
    }

    // Detect the current language from the URL
    const pathSegments = window.location.pathname.split('/').filter(segment => segment);
    const currentLanguage = pathSegments.find(segment => SUPPORTED_LANGUAGES.includes(segment));

    // Update the UI if a language is detected
    if (currentLanguage) {
        updateLanguageDisplay(currentLanguage);
    }
}
