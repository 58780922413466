import * as SessionManagement from './sessionManagement';
import * as VisitorTracking from '../visitorTracking';

export function initializeEnrollNow() {
// Enroll Now! button event listener
        // Function to safely initialize Bootstrap modals
        function safeInitModal(elementId) {
            const element = document.getElementById(elementId);
            if (element) {
                return new bootstrap.Modal(element);
            }
            return null;  // Return null if element is not found
        }

        const enrollmentModal = safeInitModal('zoniModal');
        const confirmNewAccountModal = safeInitModal('confirmNewAccountModal');

        document.querySelectorAll('.enroll-btn').forEach(button => {
            button.addEventListener('click', async function () {
                try {
                    // Fetch login status
                    const loginStatus = await SessionManagement.getLoginStatus();
                    console.log(loginStatus.message.logged_in);
        
                    if (loginStatus.message.logged_in) {
                        // Display the user account using loginStatus.message.user
                        document.getElementById('displayUserAccountName').innerText = `${loginStatus.message.user}`;
                        confirmNewAccountModal?.show();
                    } else {
                        // Hide all open modals
                        var openModals = document.querySelectorAll('.modal.show');
                        openModals.forEach(modal => {
                            var bootstrapModal = bootstrap.Modal.getInstance(modal);
                            bootstrapModal.hide();
                        });
                        VisitorTracking.customizeSchoolEnrollment()
                        enrollmentModal?.show();
                    }
                } catch (error) {
                    console.error('Error fetching login status:', error);
                    // Handle error if needed
                }
            });
        });

        const createAccountYesButton = document.getElementById('createAccountYes');
        const createAccountNoButton = document.getElementById('createAccountNo');
        
        if (createAccountYesButton) {
            createAccountYesButton.addEventListener('click', function() {
                // Hide all open modals
                var openModals = document.querySelectorAll('.modal.show');
                openModals.forEach(modal => {
                    var bootstrapModal = bootstrap.Modal.getInstance(modal);
                    bootstrapModal.hide();
                });
                VisitorTracking.customizeSchoolEnrollment(); // Customize the enrollment modal for school enrollment.
                enrollmentModal?.show(); // Then show the enrollment modal.
            });
        }
        
        if (createAccountNoButton) {
            createAccountNoButton.addEventListener('click', function() {
                confirmNewAccountModal?.hide(); // Optionally, hide the confirmation modal before redirecting.
                window.location.href = '/school/portal/home';
            });
        }
}