export let documentLanguage = document.documentElement.lang; // Detect the language set in the HTML document;
/**
 * Translates a given text to the specified target language using a Frappe API endpoint.
 * If the detected language starts with "en", returns the original text immediately.
 * 
 * @param {string} text The text to translate.
 * @returns {Promise<string>} A promise that resolves to the translated text or the original text if the language starts with "en".
 */
export function translateText(text) {
    // Use the global language variable or default to 'en' if not set
    const targetLanguage = documentLanguage || 'en';

    // If the language starts with 'en', return the original text immediately
    if (targetLanguage.startsWith('en')) {
        return Promise.resolve(text);
    }

    // Construct the URL for the API request
    const apiUrl = `/api/method/zoni_edu.controller.get_translation`;
    const params = new URLSearchParams({ text, target_language: targetLanguage });

    // Use the Fetch API to send a request to the Frappe API
    return fetch(`${apiUrl}?${params.toString()}`, {
        method: 'GET',
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then(data => {
        // Assuming the API returns a JSON object with the translated text
        return data.message; // Adjust this based on the actual API response structure
    })
    .catch(error => {
        console.error('Error fetching the translation:', error);
    });
}

// Example usage:
// translateText('Submit').then(translatedText => {
//    console.log(translatedText); // Outputs the translated text or the original text if the language starts with "en"
    // E.g., to set the text content of an element:
    // document.getElementById('submitBtn').textContent = translatedText;
//});
