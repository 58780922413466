export function initializeFilters() {
    const location = document.getElementById('filter-location');
    const type = document.getElementById('filter-type');
    const title = document.getElementById('filter-title');

    if (!(location) && !(type) && !(title)) {
        return; // Exit the function early to avoid rendering jobs
    }

    // Initialize Select2 after fetching the select elements
    if (location && !$(location).hasClass('select2-hidden-accessible')) {
        $(location).select2({ theme: 'bootstrap-5' });
    }
    if (type && !$(type).hasClass('select2-hidden-accessible')) {
        $(type).select2({ theme: 'bootstrap-5' });
    }
    if (title && !$(title).hasClass('select2-hidden-accessible')) {
        $(title).select2({ theme: 'bootstrap-5' });
    }
}

export function fetchJobs() {
    const location = document.getElementById('filter-location');
    const type = document.getElementById('filter-type');
    const title = document.getElementById('filter-title');

    if (!(location) && !(type) && !(title)) {
        return; // Exit the function early to avoid rendering jobs
    }

    // Initialize Select2 after fetching the select elements
    if (location && !$(location).hasClass('select2-hidden-accessible')) {
        $(location).select2({ theme: 'bootstrap-5' });
    }
    if (type && !$(type).hasClass('select2-hidden-accessible')) {
        $(type).select2({ theme: 'bootstrap-5' });
    }
    if (title && !$(title).hasClass('select2-hidden-accessible')) {
        $(title).select2({ theme: 'bootstrap-5' });
    }

    // Get values from Select2 dropdowns
    const locationValues = $(location).select2('val');
    const typeValues = $(type).select2('val');
    const titleValues = $(title).select2('val');

    const locationTexts = $("#filter-location").select2('data').map(function(elem){
        return elem.text;  // extract text from each selected option
    });
    

    let url = `/api/method/zoni_edu.controller.get_job_openings`;  // We are fetching all jobs for filtering on frontend
  
    // Fetch jobs using the Fetch API
    fetch(url, {
        method: 'GET'
    })
        .then(response => {
            if (!response.ok) {
                console.log('Full Response:', response);
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            let jobs = data.message;


            // 1. Separate remote jobs from others
            let remoteJobs = jobs.filter(job => job.work_mode === 'Remote');
            let otherJobs = jobs.filter(job => job.work_mode !== 'Remote');

            // Check if the location filter includes ", NY,"
            const nyFilterActive = locationValues.some(value => value.includes(', NY,'));


            // 2. Apply location filter to non-remote jobs
            if (locationValues && locationValues.length) {
                otherJobs = otherJobs.filter(job => locationValues.includes(job.location));
            }
            

            // 3. Merge remote jobs back to the list
            // Conditionally merge remote jobs back to the list
            if (!nyFilterActive) {
                jobs = [...otherJobs, ...remoteJobs ];
            } else {
                jobs = [...otherJobs];
            }
    
            // 4. Apply title and type filters
            if (titleValues && titleValues.length) {
                jobs = jobs.filter(job => titleValues.includes(job.designation));
            }

            if (typeValues && typeValues.length) {
                jobs = jobs.filter(job => typeValues.includes(job.work_mode));
            }


            // Render jobs
            let jobHtml = '';
            let count = 0;  // to keep track of every 3 items

            for (const job of jobs) {
                // For small devices, every job will have its own carousel-item
                if (window.innerWidth <= 768) {
                    jobHtml += `<div class="carousel-item ${count === 0 ? 'active' : ''}"><div class="row d-flex">`;
                }
                // For medium and large devices, every 3 jobs will be grouped into one carousel-item
                else if (count % 3 === 0) {
                    jobHtml += count !== 0 ? `</div></div>` : '';
                    jobHtml += `<div class="carousel-item ${count === 0 ? 'active' : ''}"><div class="row d-flex">`;
                }
                let imageSrc = '';
                if (job.image) {
                    imageSrc = job.image;
                }
                jobHtml += `
                <div class="col-12 col-md-4 mb-3">
                    <div class="card flex-fill h-100 position-relative">
                        <img class="img-fluid" alt="${job.title} Image" src="${imageSrc}">

                        <!-- Overlay over the image -->
                        <div class="overlay_over_image position-absolute top-0 start-0 w-100 h-100" style="background-color: rgba(0, 74, 147, 0.4);"></div>
          
                        
                        <!-- Positioning text over the image -->
                        <div class="position-absolute top-0 start-0 p-2 mt-5 text-white rounded-end" style="background-color: rgba(239, 62, 66, 1);">
                            <strong>${job.location}</strong>
                        </div>
                        
                        <div class="position-absolute top-50 start-50 translate-middle text-center text-white">
                            <h4 style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);">${job.job_title}</h4>
                        </div>
                        
                        <div class="position-absolute bottom-0 end-0 p-5">
                            <a href="${job.detail_link}" class="btn btn-danger">Apply here</a>
                        </div>
                        
                    </div>
                </div>
            `;
                count++;
                if (window.innerWidth <= 768) {
                    jobHtml += '</div></div>'; // Close the carousel-item for small devices after every job
                }
                else if (count % 3 === 0) {
                    jobHtml += '</div></div>'; // Close the carousel-item for medium and large devices after every 3 jobs
                }
            }

            document.querySelector('#carouselExampleIndicators2 .carousel-inner').innerHTML = jobHtml;


            // Calculate the number of carousel items created
            const numberOfSlides = (window.innerWidth <= 768) ? jobs.length : Math.ceil(jobs.length / 3);

            // Create carousel indicators
            let indicatorsHtml = '';
            for (let i = 0; i < numberOfSlides; i++) {
                if (i === 0) {
                    indicatorsHtml += `<li data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="${i}" class="active"></li>`;
                } else {
                    indicatorsHtml += `<li data-bs-target="#carouselExampleIndicators2" data-bs-slide-to="${i}"></li>`;
                }
            }

            // Update the DOM with the newly created indicators
            document.querySelector('.carousel-indicators').innerHTML = indicatorsHtml;


        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error.message);
        });
}
