export function updatePhoneNumber() {
    const phoneNumberMapping = {
        "US": "+1-888-495-0680",
        "TH": "+66-250-60406",
        "EC": "+593-963-365891",
        "VN": "+84-244-4582265",
        "CO": "+57-333-602-6224",
        "TW": "+886-255-925088",
        "PA": "+507-833-7632",
        "MX": "+52-554-160-6058",
        "AR": "+54-113-988-4015",
        "BR": "+55-312-342-1247",
        "CR": "+506-40014377",
        "PE": "+51-17028606",
        "CL": "+56-223683077",
        "CN": "+86-108-524-1274",
        "KR": "+82-707-4885131",
        "DO": "+1-829-94-77544"
    };

    fetch('/api/method/zoni_edu.controller.get_country_from_ip', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        }
    })
    .then(response => response.json())
    .then(data => {
        //console.log('Country based on IP:', data.message);

        let phoneNumber = phoneNumberMapping[data.message] || '+1-212-736-9000';

         // If the country detected is Brazil, adjust the phone number accordingly
        //if (data.message === 'BR') {
        //    phoneNumber = '(031) 2342-1247';
        //}

        // Update the displayed phone number
        $('.header_contact_phone_text').html(phoneNumber);
        $('.header_contact_phone_href').attr('href', 'tel:' + phoneNumber.replace(/[-\s]/g, ''));

    })
    .catch(error => {
        console.error('Error fetching country based on IP:', error);
    });
}
