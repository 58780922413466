export function transformYearsProgramSectionByResize() {
    if ($(window).width() < 991) {
        $('#accordionYear1-button').attr("data-bs-target", "#flush-collapseYearOne");
        $('#accordionYear2-button').attr("data-bs-target", "#flush-collapseYearTwo");
        $('#accordionYear3-button').attr("data-bs-target", "#flush-collapseYearThree");
        $('#accordionYear4-button').attr("data-bs-target", "#flush-collapseYearFour");

        $('#flush-collapseYearOne').removeClass("show");
        $('#flush-collapseYearTwo').removeClass("show");
        $('#flush-collapseYearThree').removeClass("show");
        $('#flush-collapseYearFour').removeClass("show");
    } else {
        $('#accordionYear1-button').removeAttr("data-bs-target");
        $('#accordionYear2-button').removeAttr("data-bs-target");
        $('#accordionYear3-button').removeAttr("data-bs-target");
        $('#accordionYear4-button').removeAttr("data-bs-target");

        $('#flush-collapseYearOne').addClass("show");
        $('#flush-collapseYearTwo').addClass("show");
        $('#flush-collapseYearThree').addClass("show");
        $('#flush-collapseYearFour').addClass("show");
    }
}