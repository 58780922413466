export function scrollToLeftById(leftActionId, containerId) {
    $(leftActionId).on('click', function (e) {
        e.preventDefault();
        var clickedCard = $(this);
        var container = $(containerId);
     
        var containerLeftEdge = container.offset().left + container.width();
var cardLeftEdge = clickedCard.offset().left + clickedCard.outerWidth(true);
var distanceToScroll = containerLeftEdge - cardLeftEdge;
        container.animate({ scrollLeft: "-="+(distanceToScroll) }, 1500);
     
    })
}

export function scrollToRightById(rightActionId, containerId) {
    $(rightActionId).on('click', function (e) {
        e.preventDefault();
     
        var clickedCard = $(this);
        var container = $(containerId);
        var scrollPosition = clickedCard.offset().left - container.offset().left + (  24);
        container.animate({ scrollLeft: "+="+(scrollPosition-clickedCard.outerWidth(true)) }, 1500);
    
    })
}
export function scrollToLeftByIdKids(leftActionId, containerId) {
    $(leftActionId).on('click', function (e) {
        e.preventDefault();
        var clickedCard = $(this);
        var container = $(containerId);
     
        var containerLeftEdge = container.offset().left + container.width();
var cardLeftEdge = clickedCard.offset().left + clickedCard.outerWidth(true);
var distanceToScroll = containerLeftEdge - cardLeftEdge + 80;
        container.animate({ scrollLeft: "-="+(distanceToScroll) }, 1500);
     
    })
}

export function scrollToRightByIdKids(rightActionId, containerId) {
    $(rightActionId).on('click', function (e) {
        e.preventDefault();
     
        var clickedCard = $(this);
        var container = $(containerId);
        var scrollPosition = clickedCard.offset().left - container.offset().left + (83);
        container.animate({ scrollLeft: "+="+(scrollPosition-clickedCard.outerWidth(true)) }, 1500);
    
    })
}